import { createGetKcContext } from 'keycloakify/login';

export const { getKcContext } = createGetKcContext({
  mockProperties: {
    documentationUrl: 'https://docs.test.eu.c-napps.com',
    registrationUrl: 'https://synapse-medicine.com/forms/documentation',
  },
});

export const { kcContext } = getKcContext();

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>;
